<template>
  <div class="content_body RefundTreatBillApply" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
            <el-form-item label="单据号">
              <el-input placeholder="输入单据号" v-model="ApplyBillId" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="客户">
              <el-input placeholder="输入会员编号、名称、手机号" v-model="Name" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="申请时间">
              <el-date-picker type="daterange" v-model="searchData" unlink-panels range-separator="至" :picker-options="pickerOptions" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSearch"></el-date-picker>
            </el-form-item>
            <el-form-item label="状态">
              <el-select placeholder="请选择审批状态" clearable v-model="ApprovalStatus" @change="handleSearch">
                <el-option label="待审批" :value="20"></el-option>
                <el-option label="已同意" :value="30"></el-option>
                <el-option label="已拒绝" :value="40"></el-option>
                <el-option label="已撤销" :value="50"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" v-prevent-click @click="searchBillList">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <el-table size="small" :data="BillList" class="martp_10">
      <el-table-column label="单据号" prop="ID"></el-table-column>
      <el-table-column label="客户信息" prop="CustomerName">
        <template slot-scope="scope">
          <div>{{scope.row.CustomerName}}</div>
          <div>手机号：{{scope.row.PhoneNumber}}</div>
        </template>
      </el-table-column>
      <el-table-column label="退消耗金额" prop="Amount"><template slot-scope="scope">￥{{scope.row.Amount | NumFormat}}</template></el-table-column>
      <!-- <el-table-column label="申请门店" prop="EntityName"></el-table-column> -->
      <el-table-column label="审批状态" prop="ApprovalStatus">
        <template slot-scope="scope">{{scope.row.ApprovalStatus == 20?"待审批":scope.row.ApprovalStatus == 30?"已同意" :scope.row.ApprovalStatus == 40?"已拒绝":'已撤销'}}</template>
      </el-table-column>
      <el-table-column label="申请时间" prop="CreatedOn"></el-table-column>
      <el-table-column label="申请人" prop="CreatedByName"></el-table-column>
      <el-table-column label="操作" width="80px">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="TreatBillInfo(scope.row)" v-prevent-click>详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="text_right pad_15">
      <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
    </div>
    <!-- 退消耗详情 -->
    <el-dialog :visible.sync="TreatBillVisible" width="1100px">
      <span slot="title">
        <span>
          退消耗详情
        </span>
      </span>
      <div style="height:60vh;font-size:13px !important">
        <el-scrollbar class="el-scrollbar_height_view">
          <el-form>
            <el-row>
              <div class="tip">退消耗信息</div>
              <el-col :span="24">
                <el-form label-width="100px" size="small" class="refundTreatBillApply">
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="退消耗单:">{{ApplyBillDetail.ID}}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="审批状态：">{{ApplyBillDetail.ApprovalStatus==20?'待审批':ApplyBillDetail.ApprovalStatus == 30?'已同意':ApplyBillDetail.ApprovalStatus == 40?'已拒绝':'已撤销'}}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="退消耗时间：">{{ApplyBillDetail.BillDate}}</el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="客户信息:">{{ApplyBillDetail.CustomerName}}({{ApplyBillDetail.PhoneNumber}})</el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="申请人：">{{ApplyBillDetail.CreatedByName}}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="申请时间：">{{ApplyBillDetail.CreatedOn}}</el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="退消耗金额:">￥{{ApplyBillDetail.Amount | NumFormat}}</el-form-item>
                    </el-col>
                    <el-col :span="16">
                      <el-form-item label="备注信息：">
                        {{ApplyBillDetail.RefundRemark}}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row v-if="IsShow">
                    <el-col :span="8">
                      <el-form-item label="审批时间:">{{ApplyBillDetail.ApprovalOn}}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="审批人：">{{ApplyBillDetail.ApprovalByName}}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="审批说明：">{{ApplyBillDetail.ApprovalRemark}}</el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-col>
            </el-row>
          </el-form>
          <div class="tip">退消耗明细</div>
          <div>
            <!-- 项目 -->
            <div v-if="ApplyBillDetail.Project!=undefined && ApplyBillDetail.Project.length>0">
              <el-row class="back_color pad_10_15">
                <el-col :span="8">项目</el-col>
                <el-col :span="8">退消耗数量</el-col>
                <el-col :span="8">退消耗金额金额</el-col>
              </el-row>
              <el-row class="border_right border_left" v-for="(item,index) in ApplyBillDetail.Project" :key="index+'Pro'">
                <el-col :span="24" class="pad_10_15 border_bottom">
                  <el-col :span="8">
                    <div>
                      {{item.Name}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                    </div>
                    <!-- <div class="color_red">￥ {{item.Amount | NumFormat}}</div> -->
                  </el-col>
                  <el-col :span="8">x {{item.Quantity}}</el-col>
                  <el-col :span="8">￥ {{item.TotalAmount | NumFormat}}</el-col>
                </el-col>
                <el-col :span="24" class="border_bottom" v-if="item.RefundBillHandler.length>0">
                  <el-row v-for="(handler, handlerIndex) in item.RefundBillHandler" :key="handlerIndex + 'h1'" class="pad_10 color_gray font_12">
                    <el-col :span="4">{{handler.RefundHandlerName}}:</el-col>
                    <el-col :span="20" class="dis_flex">
                      <div v-for="(empItem, empIndex) in handler.Employee" :key="empIndex + 'emp1'">
                        <span class="marlt_10">[{{empItem.EmployeeID}}] {{empItem.EmployeeName}} :</span>
                        <span class="marlt_10">{{empItem.Scale.toFixed(2)}}%</span>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <!-- 产品 -->
            <div v-if="ApplyBillDetail.Product!=undefined && ApplyBillDetail.Product.length>0">
              <el-row class="back_color pad_10_15">
                <el-col :span="8">产品</el-col>
                <el-col :span="8">退消耗数量</el-col>
                <el-col :span="8">退消耗金额</el-col>
              </el-row>
              <el-row class="border_right border_left" v-for="(item,index) in ApplyBillDetail.Product" :key="index+'Prod'">
                <el-col :span="24" class="pad_10_15 border_bottom">
                  <el-col :span="8">
                    <div>
                      {{item.Name}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                    </div>
                    <!-- <div class="color_red">￥ {{item.Amount | NumFormat}}</div> -->
                  </el-col>
                  <el-col :span="8">x {{item.Quantity}}</el-col>
                  <el-col :span="8">￥ {{item.TotalAmount | NumFormat}}</el-col>
                </el-col>
                <el-col :span="24" class="border_bottom" v-if="item.RefundBillHandler.length>0">
                  <el-row v-for="(handler, handlerIndex) in item.RefundBillHandler" :key="handlerIndex + 'h1'" class="pad_10 color_gray font_12">
                    <el-col :span="4">{{handler.RefundHandlerName}}:</el-col>
                    <el-col :span="20" class="dis_flex">
                      <div v-for="(empItem, empIndex) in handler.Employee" :key="empIndex + 'emp1'">
                        <span class="marlt_10">[{{empItem.EmployeeID}}] {{empItem.EmployeeName}} :</span>
                        <span class="marlt_10">{{empItem.Scale.toFixed(2)}}%</span>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <!-- 通用次卡 -->
            <div v-if="ApplyBillDetail.GeneralCard!=undefined && ApplyBillDetail.GeneralCard.length>0">
              <el-row class="back_color pad_10_15 border_bottom">
                <el-col :span="8">通用次卡</el-col>
                <el-col :span="8">退消耗数量</el-col>
                <el-col :span="8">退消耗金额</el-col>
              </el-row>
              <el-row class="border_right border_left" v-for="(item,index) in ApplyBillDetail.GeneralCard" :key="index+'gen'">
                <el-col :span="24" class="pad_10_15 border_bottom backf5f7fa">
                  <el-col :span="24">
                    <span> {{item.Name}} <span v-if="item.Alias">({{item.Alias}})</span> </span>
                  </el-col>
                </el-col>

                <el-row v-for="(project,projectIndex) in item.Project" :key="'g_p_' + projectIndex">
                  <el-col class="pad_10_15 border_bottom" :span="24">
                    <el-col :span="8">
                      <div> {{project.ProjectName}} <span v-if="project.Alias">({{project.Alias}})</span> </div>
                      <!-- <div class="color_red">￥ {{project.Amount | NumFormat}}</div> -->
                    </el-col>
                    <el-col :span="8">x {{project.Quantity}}</el-col>
                    <el-col :span="8">￥ {{project.TotalAmount | NumFormat}}</el-col>

                  </el-col>
                  <el-col :span="24" class="border_bottom" v-if="project.RefundBillHandler.length>0">
                    <el-row v-for="(handler, handlerIndex) in project.RefundBillHandler" :key="handlerIndex + 'h1'" class="pad_10 color_gray font_12">
                      <el-col :span="4">{{handler.RefundHandlerName}}:</el-col>
                      <el-col :span="20" class="dis_flex">
                        <div v-for="(empItem, empIndex) in handler.Employee" :key="empIndex + 'emp1'">
                          <span class="marlt_10">[{{empItem.EmployeeID}}] {{empItem.EmployeeName}} :</span>
                          <span class="marlt_10">{{empItem.Scale.toFixed(2)}}%</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-row>
            </div>
            <!-- 时效卡 -->
            <div v-if="ApplyBillDetail.TimeCard!=undefined && ApplyBillDetail.TimeCard.length>0">
              <el-row class="back_color pad_10_15 border_bottom">
                <el-col :span="8">时效卡</el-col>
                <el-col :span="8">退消耗数量</el-col>
                <el-col :span="8">退消耗金额</el-col>
              </el-row>
              <el-row class="border_right border_left" v-for="(item,index) in ApplyBillDetail.TimeCard" :key="index+'TIME'">
                <el-col :span="24" class="pad_10_15 border_bottom backf5f7fa">
                  <el-col :span="12">
                    <div>
                      {{item.Name}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                    </div>
                    <!-- <div class="color_red">￥ {{item.TotalAmount | NumFormat}}</div> -->
                  </el-col>
                  <el-col :span="6" style=" border: 1px solid transparent;"><span v-if="item.Quantity">x {{item.Quantity}}</span> </el-col>
                  <!-- <el-col :span="6">￥ {{item.Amount | NumFormat}}</el-col> -->
                </el-col>
                <el-row v-for="(project,projectIndex) in item.Project" :key="'t_p_' + projectIndex">
                  <el-col class="pad_10_15 border_bottom" :span="24">
                    <el-col :span="8">
                      <div>
                        {{project.ProjectName}}
                        <span v-if="project.Alias">({{project.Alias}})</span>
                      </div>
                      <!-- <div class="color_red">￥ {{project.Amount | NumFormat}}</div> -->
                    </el-col>
                    <el-col :span="8" style=" border: 1px solid transparent;"><span v-if="project.Quantity">x {{project.Quantity}}</span> </el-col>
                    <el-col :span="8">￥ {{project.TotalAmount | NumFormat}}</el-col>

                  </el-col>
                  <el-col :span="24" class="border_bottom" v-if="project.RefundBillHandler.length>0">
                    <el-row v-for="(handler, handlerIndex) in project.RefundBillHandler" :key="handlerIndex + 'h1'" class="pad_10 color_gray font_12">
                      <el-col :span="4">{{handler.RefundHandlerName}}:</el-col>
                      <el-col :span="20" class="dis_flex">
                        <div v-for="(empItem, empIndex) in handler.Employee" :key="empIndex + 'emp1'">
                          <span class="marlt_10">[{{empItem.EmployeeID}}] {{empItem.EmployeeName}} :</span>
                          <span class="marlt_10">{{empItem.Scale.toFixed(2)}}%</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>

                </el-row>
              </el-row>
            </div>
            <!-- 储值卡 -->
            <div v-if="ApplyBillDetail.SavingCard!=undefined && ApplyBillDetail.SavingCard.length>0">
              <el-row class="back_color pad_10_15 border_bottom">
                <el-col :span="8">储值卡</el-col>
                <el-col :span="8">退消耗数量</el-col>
                <el-col :span="8">退消耗金额</el-col>
              </el-row>
              <el-row class="border_right border_left" v-for="(item,index) in ApplyBillDetail.SavingCard" :key="index+'SAVE'">
                <el-col :span="24" class="pad_10_15 border_bottom backf5f7fa">
                  <el-col :span="12">
                    <div>
                      {{item.Name}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                    </div>
                  </el-col>
                </el-col>
                <el-row v-for="(project,projectIndex) in item.Project" :key="'s_p_' + projectIndex">
                  <el-col class="pad_10_15 border_bottom" :span="24">
                    <el-col :span="8">
                      <div>
                        {{project.ProjectName}}
                        <span v-if="project.Alias">({{project.Alias}})</span>
                      </div>
                      <!-- <div class="color_red" v-if="project.Price">￥ {{project.Price | NumFormat}}</div> -->
                    </el-col>
                    <el-col :span="8" style=" border: 1px solid transparent;"><span v-if="project.Quantity">x {{project.Quantity}}</span>
                    </el-col>
                    <el-col :span="8">
                      <!-- <div>¥ {{project.TotalAmount | NumFormat}}</div>
                      <div class="font_10 color_999 martp_5"> -->
                      <!-- <div v-if="project.Amount">非赠送金额: ￥ {{project.Amount | NumFormat}}</div>
                      <div v-if="project.LargessAmount">赠送金额: ¥ {{project.LargessAmount | NumFormat}}</div> -->
                      <!-- </div> -->
                      ￥{{project.Amount+project.LargessAmount| NumFormat}}
                    </el-col>
                  </el-col>

                  <el-col :span="24" class="border_bottom" v-if="project.RefundBillHandler.length>0">
                    <el-row v-for="(handler, handlerIndex) in project.RefundBillHandler" :key="handlerIndex + 'h1'" class="pad_10 color_gray font_12">
                      <el-col :span="4">{{handler.RefundHandlerName}}:</el-col>
                      <el-col :span="20" class="dis_flex">
                        <div v-for="(empItem, empIndex) in handler.Employee" :key="empIndex + 'emp1'">
                          <span class="marlt_10">[{{empItem.EmployeeID}}] {{empItem.EmployeeName}} :</span>
                          <span class="marlt_10">{{empItem.Scale.toFixed(2)}}%</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-row>
            </div>

            <!-- 套餐卡 -->
            <div v-if="ApplyBillDetail.PackageCard!=undefined && ApplyBillDetail.PackageCard.length>0">
              <el-row class="border_right border_left" v-for="(item,index) in ApplyBillDetail.PackageCard" :key="index +'pack'">
                <el-row class="back_color pad_10">
                  <el-col :span="8">{{item.Name}}<span v-if="item.Alias">({{item.Alias}})</span></el-col>
                </el-row>
                <!-- 项目 -->
                <el-row v-if="item.Project.length > 0">
                  <el-row class="pad_10 row_header_package_detail border_bottom">
                    <el-col :span="8">套餐卡项目</el-col>
                    <el-col :span="8">退消耗数量</el-col>
                    <el-col :span="8">退消耗金额</el-col>
                  </el-row>
                  <el-row v-for="(Project,ProjectIndex) in item.Project" :key="ProjectIndex+'project'">
                    <el-col :span="24" class="pad_10 border_bottom">
                      <el-col :span="8">
                          {{Project.Name}}
                          <span v-if="Project.Alias">({{Project.Alias}})</span>
                          <!-- <el-tag size="mini" effect="plain" class="marlt_5">项目</el-tag> -->
                      </el-col>
                      <el-col :span="8">x {{Project.Quantity}}</el-col>
                      <el-col :span="8">￥ {{Project.TotalAmount | NumFormat}}</el-col>
                    </el-col>
                    <el-col :span="24" class="border_bottom" v-if="Project.RefundBillHandler.length>0">
                      <el-row v-for="(handler, handlerIndex) in Project.RefundBillHandler" :key="handlerIndex + 'h1'" class="pad_10 color_gray font_12">
                        <el-col :span="4">{{handler.RefundHandlerName}}:</el-col>
                        <el-col :span="20" class="dis_flex">
                          <div v-for="(empItem, empIndex) in handler.Employee" :key="empIndex + 'emp1'">
                            <span class="marlt_10">[{{empItem.EmployeeID}}] {{empItem.EmployeeName}} :</span>
                            <span class="marlt_10">{{empItem.Scale.toFixed(2)}}%</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-row>
                <!-- 产品 -->
                <el-row v-if="item.Product.length > 0">
                  <el-row class="pad_10 row_header_package_detail border_bottom">
                    <el-col :span="8">套餐卡产品</el-col>
                    <el-col :span="8">退消耗数量</el-col>
                    <el-col :span="8">退消耗金额</el-col>
                  </el-row>
                  <el-row v-for="(Product,Productindex) in item.Product" :key="Productindex+'product'">
                    <el-col :span="24" class="pad_10_15 border_bottom">
                      <el-col :span="8">
                          {{Product.Name}}
                          <span v-if="Product.Alias">({{Product.Alias}})</span>
                      </el-col>
                      <el-col :span="8">x {{Product.Quantity}}</el-col>
                      <el-col :span="8">￥ {{Product.TotalAmount | NumFormat}}</el-col>
                    </el-col>
                    <el-col :span="24" class="border_bottom" v-if="Product.RefundBillHandler.length>0">
                      <el-row v-for="(handler, handlerIndex) in Product.RefundBillHandler" :key="handlerIndex + 'h1'" class="pad_10 color_gray font_12">
                        <el-col :span="4">{{handler.RefundHandlerName}}:</el-col>
                        <el-col :span="20" class="dis_flex">
                          <div v-for="(empItem, empIndex) in handler.Employee" :key="empIndex + 'emp1'">
                            <span class="marlt_10">[{{empItem.EmployeeID}}] {{empItem.EmployeeName}} :</span>
                            <span class="marlt_10">{{empItem.Scale.toFixed(2)}}%</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-row>
                <!-- 时效卡 -->
                <el-row v-for="(timeCardItem,index) in item.TimeCard" :key="index+'SavingCardss'">
                  <el-col :span="24" class="pad_10_15 border_bottom backf5f7fa">
                    <el-col :span="8">
                      {{timeCardItem.Name}} 
                      <span v-if="timeCardItem.Alias">({{timeCardItem.Alias}})</span>
                      <el-tag size="mini" effect="plain" class="marlt_5">时效卡</el-tag>
                    </el-col>
                    <el-col :span="8">退消耗数量</el-col>
                    <el-col :span="8">退消耗金额</el-col>
                  </el-col>
                  <el-row v-for="(project,projectIndex) in timeCardItem.Project" :key="'g_p_' + projectIndex">
                    <el-col class="pad_10_15 border_bottom" :span="24">
                      <el-col :span="8">{{project.ProjectName}} <span v-if="project.Alias">({{project.Alias}})</span> </el-col>
                      <el-col :span="8">x {{project.Quantity}}</el-col>
                      <el-col :span="8">￥ {{project.TotalAmount | NumFormat}}</el-col>
                    </el-col>
                    <el-col :span="24" class="border_bottom" v-if="project.RefundBillHandler.length>0">
                      <el-row v-for="(handler, handlerIndex) in project.RefundBillHandler" :key="handlerIndex + 'h1'" class="pad_10 color_gray font_12">
                        <el-col :span="4">{{handler.RefundHandlerName}}:</el-col>
                        <el-col :span="20" class="dis_flex">
                          <div v-for="(empItem, empIndex) in handler.Employee" :key="empIndex + 'emp1'">
                            <span class="marlt_10">[{{empItem.EmployeeID}}] {{empItem.EmployeeName}} :</span>
                            <span class="marlt_10">{{empItem.Scale.toFixed(2)}}%</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>

                </el-row>
                <!-- 储值卡 -->
                <el-row v-for="(savingCardItem,index) in item.SavingCard" :key="index+'1'">
                  <el-col :span="24" class="pad_10_15 border_bottom backf5f7fa">
                    <el-col :span="8">
                      {{savingCardItem.Name}} 
                      <span v-if="savingCardItem.Alias">({{savingCardItem.Alias}})</span>
                      <el-tag size="mini" effect="plain" class="marlt_5">储值卡</el-tag>
                    </el-col>
                    <el-col :span="8">退消耗数量</el-col>
                    <el-col :span="8">退消耗金额</el-col>
                  </el-col>

                  <el-row v-for="(project,projectIndex) in savingCardItem.Project" :key="'g_p_' + projectIndex">
                    <el-col class="pad_10_15 border_bottom" :span="24">
                      <el-col :span="8">
                        {{project.ProjectName}} <span v-if="project.Alias">({{project.Alias}})</span> 
                      </el-col>
                      <el-col :span="8">x {{project.Quantity}}</el-col>
                      <el-col :span="8">
                        <div>¥ {{project.TotalAmount | NumFormat}}</div>
                      </el-col>
                    </el-col>
                    <el-col :span="24" class="border_bottom" v-if="project.RefundBillHandler.length>0">
                      <el-row v-for="(handler, handlerIndex) in project.RefundBillHandler" :key="handlerIndex + 'h1'" class="pad_10 color_gray font_12">
                        <el-col :span="4">{{handler.RefundHandlerName}}:</el-col>
                        <el-col :span="20" class="dis_flex">
                          <div v-for="(empItem, empIndex) in handler.Employee" :key="empIndex + 'emp1'">
                            <span class="marlt_10">[{{empItem.EmployeeID}}] {{empItem.EmployeeName}} :</span>
                            <span class="marlt_10">{{empItem.Scale.toFixed(2)}}%</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-row>
                <!-- 通用次卡 -->
                <el-row v-for="(GeneralCardItem,GeneralCardindex) in item.GeneralCard" :key="GeneralCardindex+'GeneralCardss'">

                  <el-col :span="24" class="pad_10_15 border_bottom backf5f7fa">
                    <el-col :span="8">
                      {{GeneralCardItem.Name}} 
                      <span v-if="GeneralCardItem.Alias">({{GeneralCardItem.Alias}})</span>
                      <el-tag size="mini" effect="plain" class="marlt_5">通用次卡</el-tag>
                    </el-col>
                    <el-col :span="8">退消耗数量</el-col>
                    <el-col :span="8">退消耗金额</el-col>
                  </el-col>

                  <el-row v-for="(project,projectIndex) in GeneralCardItem.Project" :key="'g_p_' + projectIndex">
                    <el-col class="pad_10_15 border_bottom" :span="24">
                      <el-col :span="8">
                       {{project.ProjectName}} <span v-if="project.Alias">({{project.Alias}})</span>
                      </el-col>
                      <el-col :span="8">x {{project.Quantity}}</el-col>
                      <el-col :span="8">￥ {{project.TotalAmount | NumFormat}}</el-col>
                    </el-col>
                    <el-col :span="24" class="border_bottom" v-if="project.RefundBillHandler.length>0">
                      <el-row v-for="(handler, handlerIndex) in project.RefundBillHandler" :key="handlerIndex + 'h1'" class="pad_10 color_gray font_12">
                        <el-col :span="4">{{handler.RefundHandlerName}}:</el-col>
                        <el-col :span="20" class="dis_flex">
                          <div v-for="(empItem, empIndex) in handler.Employee" :key="empIndex + 'emp1'">
                            <span class="marlt_10">[{{empItem.EmployeeID}}] {{empItem.EmployeeName}} :</span>
                            <span class="marlt_10">{{empItem.Scale.toFixed(2)}}%</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>

                </el-row>
              </el-row>
            </div>

            <div class="border_left border_right border_bottom pad_10_0">
              <el-row>
                <el-col :span="6" class="text_right" :offset="17">
                  <el-form class="refundTreatBillApply" size="mini">
                    <el-form-item label="合计金额：">
                      <div class="text_right">￥{{ApplyBillDetail.Amount |NumFormat}}</div>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div slot="footer" v-if="IsCheck" class="text_right">
        <el-button @click="TreatBillVisible =false" size="small">取消</el-button>
        <el-button type="danger" @click="confrimWithdrawApplyClick" :loading="cancelSaleBillLoading" size="small">撤销申请</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import API from "@/api/iBeauty/RefundBill/refundTreatBillApply";
export default {
  name: "RefundTreatBillApply",
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      TreatBillVisible: false,
      withdrawDialog: false,
      IsShow: true,
      IsCheck: false,
      loading: false,
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      BillList: [],
      ApplyBillId: "",
      Name: "",
      searchData: "",
      StartDate: "",
      EndDate: "",
      ApprovalStatus: "",
      ApplyBillDetail: "",
      TreatBillApplyID: "",
      cancelSaleBillLoading: false,
    };
  },
  methods: {
    handleCurrentChange(page) {
      this.paginations.page = page;
      this.searchBillList();
    },
    handleSearch() {
      var that = this;
      that.paginations.page = 1;
      that.searchBillList();
    },
    searchBillList() {
      var that = this;
      that.loading = true;
      if (that.searchData == null || that.searchData == "") {
        that.StartDate = "";
        that.EndDate = "";
      } else {
        that.StartDate = that.searchData[0];
        that.EndDate = that.searchData[1];
      }
      var params = {
        PageNum: that.paginations.page,
        ID: that.ApplyBillId,
        Name: that.Name,
        StartDate: that.StartDate,
        EndDate: that.EndDate,
        ApprovalStatus: that.ApprovalStatus,
      };
      API.getTreatApplyList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.BillList = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //详情
    TreatBillInfo(row) {
      var that = this;
      that.loading = true;
      that.TreatBillApplyID = row.ID;
      var params = {
        ID: row.ID,
      };
      API.getTreatApplyDetail(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.ApplyBillDetail = res.Data;
            that.TreatBillVisible = true;
            if (
              that.ApplyBillDetail.ApprovalStatus == 20 ||
              that.ApplyBillDetail.ApprovalStatus == 50 ||
              that.ApplyBillDetail.ApprovalStatus == 40
            ) {
              that.IsShow = false;
            } else {
              that.IsShow = true;
            }
            if (that.ApplyBillDetail.ApprovalStatus == 20) {
              that.IsCheck = true;
            } else {
              that.IsCheck = false;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //撤销申请
    CancelTreatBillApply() {
      var that = this;
      this.$confirm("此操作将取消当前订单, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.cancelSaleBillLoading = true;
          var params = {
            ID: that.TreatBillApplyID,
          };
          API.refundTreatBillrevoke(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success({
                  message: "撤销申请成功",
                  duration: 2000,
                });
                that.searchBillList();
                that.withdrawDialog = false;
                that.TreatBillVisible = false;
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        })
        .finally(function () {
          that.cancelSaleBillLoading = false;
        });
    },
    confrimWithdrawApplyClick() {
      this.CancelTreatBillApply();
    },
  },
  mounted() {
    var that = this;
    // var date = new Date(),
    //   y = date.getFullYear(),
    //   m = date.getMonth();
    // that.searchData = [new Date(y, m, 1), new Date()];
    that.searchBillList();
  },
};
</script>

<style  lang="scss">
.RefundTreatBillApply {
  .el-scrollbar_height_view {
    height: 100%;
    margin-bottom: 30px;
    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
      .el-scrollbar__view {
        margin-bottom: 30px;
      }
    }
  }
  .back_color {
    background-color: #ecf8ff;
  }
  // .dialog-footer {
  //   text-align: right;
  // }

  .refundTreatBillApply {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }
  .row_header_package_detail {
    background-color: #f5f7fa;
    padding: 8px;
    color: #303133;
  }
}
</style>